import { Ref, ref } from 'vue';
import { $loading } from '@/api/index';
import { toast } from '@/utils/toast';

export interface Brnews {
  office_name: string;
  tpctname: string;
  sicdname: string;
  office_flag: string;
  tpctcode: string;
  sicdcode: string;
  day_qunt: number;
  assign_qunt: number;
  plan_month: number;
  sale_month: number;
  rate_month: number;
  plan_year: number;
  sale_year: number;
  rate_year: string;
}

export interface Gnmu {
  salecode: string;
  salename: string;
}

export function UseBrnews() {

  const brnewss: Ref<Brnews[]> = ref([]);
  const gnmus: Ref<Gnmu[]> = ref([]);

  const isConnected = ref(true);

  async function fetchDatas(pcdate: string) {
    await $loading(`/brnews?pc_date=${pcdate}`, 'POST').then(
      (res: any) => {
        if (res.data == ''){
          brnewss.value = [];
          toast('데이터가 존재하지 않습니다.'); 
        } else {
          brnewss.value = null;
          brnewss.value = res.data;
        }
      }
    );
  }     

  return {
    isConnected,
    fetchDatas,
    brnewss,
    gnmus,
  };
}
